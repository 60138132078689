import React from "react"

import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import Navigation from "../components/navigation/navigation"

import Footer from "../components/sections/footer"

import styled from "styled-components"
import { Container } from "../components/global"

const ImprintPage = () => {

    return (
    <Layout>
        <Seo title="apocha" />
        <Navigation />
        <DataProtectionContainer>
            <h3>Impressum</h3>

            <h5>Angaben gem&auml;&szlig; &sect; 5 TMG</h5>
			<p>
				apocha UG (haftungsbeschr&auml;nkt)<br />
				Im Brachmoos 12<br />
				88149 Nonnenhorn
			</p>

			<p>
				Handelsregister: HRB 15286<br />
				Registergericht: Amtsgericht Kempten (Allg&auml;u)<br />
				Umsatzsteuer-Identifikationsnummer gem. § 27 a Umsatzsteuergesetz: DE331393450
			</p>

			<p>
				Vertretungsberechtigter Geschäftsführer: Olaf Kortl&uuml;ke
			</p>

            <h5>Kontakt</h5>
            <p>
                Telefon: +4915154809866 (kein Support)<br />
                E-Mail: &nbsp;&nbsp;&nbsp;olaf@apocha.biz (kein Support)<br />
                <br />
                Supportanfragen bitte an support@apocha.app
            </p>

			<h5>EU-Streitschlichtung</h5>
			<p>
				Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:&nbsp;
				<a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>.<br /> 
				Unsere E-Mail-Adresse finden Sie oben im Impressum.
			</p>

			<h5>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h5>
			<p>
				Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
			</p>

            <h5>Haftung f&uuml;r Inhalte</h5>
            <p>
                Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte 
                auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 
                TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder
                gespeicherte fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen,
                die auf eine rechtswidrige T&auml;tigkeit hinweisen.
            </p>
            <p>
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab
                dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von
                entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
            
            <h5>Haftung f&uuml;r Links</h5>
            <p>
                Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren Inhalte wir keinen
                Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr
                &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige
                Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
            </p>
            <p>
                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
                einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                Links umgehend entfernen.</p>
            <h5>Urheberrecht</h5>
            <p>
                Die durch die Seitenbetreiber erstellten
                Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielf&auml;ltigung,
                Bearbeitung, Verbreitung und jede Art der Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes
                bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
                dieser Seite sind nur f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet.
            </p>
            <p>
                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem
                auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
            </p>

            <p>Quelle: <a href="https://www.e-recht24.de" rel="nofollow noopener noreferrer" target="_blank">eRecht24</a></p>
        </DataProtectionContainer>
        <Footer />
    </Layout>
)
}

export default ImprintPage

const DataProtectionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 80px 18px 40px;
`
